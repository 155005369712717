@import '../../App.scss'; //Obtener las variables que son comunes de aca (colores principales, medidas de elemento)

.text-style{
    font-size: 13px;
}

.b-g {
    background: #0A1D9B;
    background-image: url(../../images/footer/ilustracion.svg);
    background-position: bottom center;
    background-repeat: no-repeat;
    // background-size: cover;
}

// .tamano {
//     padding-bottom: 9%; 
// }

@media (max-width: 2000px) {
    .tamano {
        padding-bottom: 4%; 
    }
}

.contenedor {
    width: 100%;
    padding-right: 45px;
    padding-left: 45px;
    margin-right: auto;
    margin-left: auto;
}

.text-color {
    color: #ffffffb8;
}