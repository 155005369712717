@import '_variables.scss'; //Obtener las variables que son comunes de aca (colores principales, medidas de elementos)

html,body {
    font-family: 'Fira Sans', sans-serif;
}

.vertical-line{
    width: 3px;
    height: 100;
    margin-top: 8%;
    background-color: #60606079;
    
    margin-bottom: 2%;

    @media (min-width: 1440px){
        height: 300px;
    }

    @media (max-width: 1439px) and (min-width: 991px){
        height: 275px;
    }

    @media(max-width: 990px){
        height: 300px;
    }
}

.md-content{
    display: flex;
}

.app-link {
    text-decoration: none !important;
}

.credifin_input {
    display: block;
    width: 100%;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    height: 30px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
    padding: 0 10px;
    
    &:focus {
        outline: 0px;
    }
}

.in_development {
    width: 100%;
    height: 600px;

    p {
        font-family: 'Fira Sans', sans-serif;
        font-weight: 300;
        font-size: 22px;
    }

    img {
        width: 200px;
        height: auto;
    }
}

.titulo-primary-color { 
    font-family: Fira Sans Condensed;
    color: $primary_color;
    font-size: 180%;

    @media (min-width: 370px){
        font-size: 230%;
    }
}

.titulo-rojo { 
    font-family: Fira Sans Condensed;
    color: $secondary_color;
    font-size: 180%;

    @media (min-width: 370px){
        font-size: 230%;
    }
}

.page-section {
    padding: 4rem 0;
}

.boton {
    background: $primary_color;
    border: none;

    &:hover {
        background: #1027c0;
    }
}