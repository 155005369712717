.icon {
    img.small {
        width: 30px;
        height: 30px;
    }

    img.big {
        width: 45px;
        height: 45px;
    }
}