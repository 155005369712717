@import '../../App.scss';
.credifin__navbar {
    width: 100%;
    height: 90px;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 7px #00000080;

    .navbar-brand {
        .img-fluid {
            width: 125px !important;
            height: auto;
            background-color: #ffffff !important;
        }
    }

    .navbar-toggle {
        border: none;

        img {
            width: 22px;
            padding-bottom: 2px;
        }
    }
}

.account-button-content{
    // background-color: $primary_color;
    background-color: white;
    display: inline-flex;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px 5px 5px 5px;
    cursor: default;

    .icon-content{
        margin-top: 2px;
        width: 25px;
        height: 25px;
    } 
    
    button{
        background-color: transparent;
        border: none;
        color: white;
        font-family: 'Fira Sans', sans-serif;
        font-weight: 400;

        @media(min-width: 1320px){
            font-size: 1.1rem;
        }

        @media(max-width: 1319px) and (min-width: 991px){
            font-size: 1rem;
        }

        @media(max-width: 991px){
            font-size: 0.8rem;
        }
    }
}

.head_container {
    height: 80px;
    background-color: #ffffff !important;

    .navbar-nav {
        width: 100%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .navbar-nav {
            font-size: 12px;
        }
    }
}

.head_logo {
    margin-right: 100px;

    img {
        width: 200px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        margin-right: 0;

        img {
            width: 150px;
        }
    }
}
