@import '_variables.scss'; //Obtener las variables que son comunes de aca (colores principales, medidas de elementos)

.menu_container {
    width: 100%;
    min-height: 100vh;
    background-color: white;

    ul {
        list-style: none;
        text-align: center;
        padding: 15px;
        margin-bottom: 65px;

        li {
            width: 100%;
            height: 65px;
            font-family: 'Fira Sans', sans-serif;
            font-weight: bold;
            border-bottom: 1px solid #9e9e9e;
            font-size: 18px;

            &:hover {
                text-decoration: underline;
            }

            .menu_wrapper {
                width: 100%;
                height: 100%;
                color: #636363;
            }
        }
    }

    .menu_footer {
        width: 100%;
        height: 200px;
        background-color: $primary_color;
    }
}

.login_button {
    background-color: $primary_color;
    color: white;
    border: none;
    height: 40px;
    border-radius: 20px;
    padding: 0 15px;

    &:hover {
        outline: none;
    }
}